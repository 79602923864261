import * as FE_URL from 'src/helpers/constants/fe-urls'

import DEV from 'src/helpers/constants/generic-env/dev'
import QA from 'src/helpers/constants/generic-env/qa'
import STAGING from 'src/helpers/constants/generic-env/staging'
import UAT from 'src/helpers/constants/generic-env/uat'
import PROD from 'src/helpers/constants/generic-env/prod'

export default function getGenericEnv(hostname: string) {
  switch (hostname) {
    case FE_URL.DEV:
      return DEV
    case FE_URL.QA_MSB:
      return QA
    case FE_URL.STAGING_MSB:
      return STAGING
    case FE_URL.UAT:
      return UAT
    case FE_URL.PROD:
      return PROD
    default:
      return DEV
  }
}
