// ** Antd imports
import { Button, Divider, Space, Typography } from 'antd'

// ** Global store
import useSettingsStore from 'src/pages/Settings/manager/settings-store'

// ** Custom components
import GlobalHistoryModal from 'src/components/GlobalHistoryModal'

// ** Constants
import MENU_CONFIG from 'src/pages/Settings/constants/MENU_CONFIG'
import { historyColumns } from 'src/pages/Settings/constants/SETTINGS_CONFIG'

type Props = {
  isHistoryEnabled: boolean
}

const SSOTitle = ({ isHistoryEnabled }: Props) => {
  const { Title, Text } = Typography
  const historyApi = useSettingsStore((state) => state.historyApi)

  return (
    <>
      <Space
        style={{ display: 'flex', justifyContent: 'space-between' }}
        data-testid='selected-settings-title'
      >
        <Title level={2} style={{ marginBottom: 0 }}>
          {MENU_CONFIG.USER_MANAGEMENT.SSO.LABEL}
        </Title>
        {isHistoryEnabled && (
          <GlobalHistoryModal
            modalTitle='Audit Trail'
            overrideMainButton={<Button>Audit Trail</Button>}
            columns={historyColumns}
            reloadData={historyApi}
          />
        )}
      </Space>
      <Text>{MENU_CONFIG.USER_MANAGEMENT.SSO.DESCRIPTION}</Text>
      <Divider />
    </>
  )
}

export default SSOTitle
