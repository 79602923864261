import { useMutation } from '@tanstack/react-query'
import useApiRequest from 'src/hooks/useApiRequest'
import useApiService from 'src/hooks/useApiService'

type Response = {
  success: boolean
  message: string
}

type Error = {
  success: boolean
  message: string
}

const useSubmitSyncAll = () => {
  const { globalApi } = useApiRequest()
  const { msUrl } = useApiService()

  return useMutation<Response, Error>({
    mutationFn: () =>
      globalApi({
        options: {
          baseUrl: msUrl.taskmanager,
          endpoint: `/api/sync-all`,
          method: 'POST'
        }
      })
  })
}

export default useSubmitSyncAll
