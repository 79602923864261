import { useQuery } from '@tanstack/react-query'
import useApiRequest from 'src/hooks/useApiRequest'
import useApiService from 'src/hooks/useApiService'

type Response = {
  success: boolean
  is_syncing: boolean
}

const useFetchSyncAllStatus = () => {
  const { globalApi } = useApiRequest()
  const { msUrl } = useApiService()

  return useQuery<Response>({
    queryKey: ['sync-all-status'],
    queryFn: () =>
      globalApi({
        options: {
          baseUrl: msUrl.taskmanager,
          endpoint: `/api/sync-all/status`,
          method: 'GET'
        }
      })
  })
}

export default useFetchSyncAllStatus
