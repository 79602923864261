export const CERTIFICATE_EXTENSIONS = ['.crt', '.p7b']

export const OPTIONS_SSO_TYPE = [
  {
    value: 'saml',
    label: 'SAML'
  }
]

export const OPTIONS_SSO_BINDING = [
  {
    value: 'HTTP_POST',
    label: 'HTTP_POST'
  }
]

export const OPTIONS_SIGNING_CERTIFICATE = [
  {
    // TODO: confirm value and label
    value: 'PingOne SSO Certificate',
    label: 'PingOne SSO Certificate for Administrator'
  }
]

export const FORM_FIELDS = {
  SSO_TYPE: {
    name: 'sso_type',
    label: 'SSO Type',
    tooltip: 'This is a sample tooltip message for SSO Type.',
    requiredTxt: 'Please select your SSO Type.',
    initialValue: OPTIONS_SSO_TYPE[0].value
  },
  ENTITY_ID: {
    name: 'entity_id',
    label: 'Entity ID',
    tooltip: 'This is a sample tooltip message for Entity ID.',
    requiredTxt: 'Please input your Entity ID.'
  },
  SSO_BINDING: {
    name: 'sso_binding',
    label: 'SSO Binding',
    tooltip: 'This is a sample tooltip message for SSO Binding.',
    requiredTxt: 'Please select your SSO Binding.',
    initialValue: OPTIONS_SSO_BINDING[0].value
  },
  ACS_ENDPOINT: {
    name: 'acs_endpoint',
    label: 'ACS Endpoint',
    tooltip: 'This is a sample tooltip message for ACS Endpoint.',
    requiredTxt: 'Please input your ACS Endpoint.'
  },
  SIGNING_CERTIFICATE: {
    name: 'signing_certificate',
    label: 'Signing Certificate',
    tooltip: 'This is a sample tooltip message for Signing Certificate.',
    requiredTxt: 'Please select your Signing Certificate.',
    initialValue: OPTIONS_SIGNING_CERTIFICATE[0].value
  },
  UPLOAD: {
    name: 'upload_file',
    label: 'Upload Signing Certificate',
    requiredTxt: 'Please upload your Signing Certificate.'
  },
  METADATA_URL: {
    name: 'idp_metadata',
    label: 'Metadata URL',
    requiredTxt: 'Please input your Metadata URL.'
  }
}
