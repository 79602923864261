import { useMutation } from '@tanstack/react-query'
import useApiRequest from 'src/hooks/useApiRequest'
import useApiService from 'src/hooks/useApiService'

type Params = {
  email: string
}

type Response = {
  success: boolean
}

type Error = {
  data: {
    errors: Record<string, string[]>
    message: string
  }
}

const useSubmitSSOTest = () => {
  const { globalApi } = useApiRequest()
  const { msUrl } = useApiService()

  return useMutation<Response, Error, Params>({
    mutationFn: (data) =>
      globalApi({
        options: {
          baseUrl: msUrl.idms,
          endpoint: '/api/test-sso',
          method: 'POST'
        },
        data
      })
  })
}

export default useSubmitSSOTest
