// ** Helpers imports **
import { ROLES_AND_PERMISSIONS } from '../../../../../helpers/constants/Auth'

// ** Constants imports **
import Role from '../constants/Role'

// ** Enums imports **
import RoleEnum from '../enums/RoleEnum'

// ** Types imports **
import type RoleOptions from '../types/RoleOptions'

export default function getRoleOptions() {
  const userRoles: string[] =
    JSON.parse(localStorage.getItem(ROLES_AND_PERMISSIONS)!)?.roles || []

  const roles: RoleOptions[] = [
    {
      value: Role.READ_ONLY,
      label: RoleEnum.getLabelById(Role.READ_ONLY)
    },
    {
      value: Role.SYSTEM_ADMINISTRATOR,
      label: RoleEnum.getLabelById(Role.SYSTEM_ADMINISTRATOR)
    },
    {
      value: Role.TRANSACTION_MANAGER,
      label: RoleEnum.getLabelById(Role.TRANSACTION_MANAGER)
    },
    {
      value: Role.TASK_MANAGER,
      label: RoleEnum.getLabelById(Role.TASK_MANAGER)
    },
    {
      value: Role.QJE_MANAGER,
      label: RoleEnum.getLabelById(Role.QJE_MANAGER)
    },
    {
      value: Role.FUll_USER,
      label: RoleEnum.getLabelById(Role.FUll_USER)
    }
  ]

  const gappifyAdminRole = RoleEnum.getLabelById(Role.GAPPIFY_ADMINISTRATOR)

  if (userRoles.includes(gappifyAdminRole)) {
    roles.unshift({
      value: Role.GAPPIFY_ADMINISTRATOR,
      label: gappifyAdminRole
    })
  }

  return roles
}
