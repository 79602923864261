/** Antd imports */
import { Button, Card, message, Space, Typography } from 'antd'
import Title from 'antd/lib/typography/Title'

/** Moment */
import moment from 'moment-timezone'

/** Components */
import TooltipIcon from '../TooltipIcon'
import SyncModal from './components/SyncModal'

/** React Imports */
import { useEffect, useState } from 'react'

/** Hooks */
import useAccrualManagerStore from './manager/accrual-manager-store'
import useSubmitSyncAll from './hooks/useSubmitSyncAll'
import useFetchSyncAllStatus from 'src/hooks/useFetchSyncAllStatus'

/** Utils */
import { SYNC_STATUSES } from './constants/constants'

const { Text } = Typography

/** Last Sync Date (placeholder) */
const timezone = 'America/Los_Angeles'
const format = 'YYYY-MM-DD, hh:mm:ss A [(]z[)]'
// Get the current date and time dynamically
const dateInPDT = moment.tz(timezone)
// Force convert to PST (Pacific Standard Time)
const dateInPST =
  dateInPDT.tz(timezone).zoneName() === 'PDT'
    ? dateInPDT.subtract(1, 'hours')
    : dateInPDT
const formattedDate = dateInPST.format(format).replace('PDT', 'PST')

const AccrualManager = () => {
  /** Local states */
  const [isSyncModalOpen, setIsSyncModalOpen] = useState(false)

  /** Global stores */
  const syncStatus = useAccrualManagerStore((state) => state.syncStatus)
  const setSyncStatus = useAccrualManagerStore((state) => state.setSyncStatus)

  /** React queries */
  const {
    data: dataFetchSyncAllStatus,
    error: errorFetchSyncAllStatus,
    isLoading: isLoadingFetchSyncAllStatus,
    isSuccess: isSuccessFetchSyncAllStatus,
    isError: isErrorFetchSyncAllStatus
  } = useFetchSyncAllStatus()

  const {
    mutate: submitSynAll,
    data: dataSubmitSyncAll,
    error: errorSubmitSyncAll,
    isLoading: isLoadingSubmitSyncAll,
    isSuccess: isSuccessSubmitSyncAll,
    isError: isErrorSubmitSyncAll
  } = useSubmitSyncAll()

  /** Helpers */
  const handleSyncAll = () => {
    submitSynAll()
  }

  /** Use effects */
  // TODO: Remove this useEffect when API for fetching sync all status is ready
  useEffect(() => {
    /** Placeholder for now */
    setSyncStatus('SUCCESS')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isSuccessFetchSyncAllStatus) {
      // Set sync status based on the response
      if (dataFetchSyncAllStatus?.is_syncing) {
        setSyncStatus('IN_PROGRESS')
      } else {
        setSyncStatus('SUCCESS')
      }
    }

    if (isErrorFetchSyncAllStatus) {
      /**
       * ? DEV NOTES:
       * ? No expected error scenarios for this query
       * ? Add error handling here if needed
       */
      console.log('Error response from API:', errorFetchSyncAllStatus)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingFetchSyncAllStatus])

  useEffect(() => {
    if (isSuccessSubmitSyncAll) {
      if (dataSubmitSyncAll.success) {
        message.success(dataSubmitSyncAll.message)
      }

      // Set status as In Progress only with this exact message response
      // This is only applicable if there are out-of-sync lines found
      // This is the current BE response for this scenario
      if (dataSubmitSyncAll.message === 'Started sync all process.') {
        setSyncStatus('IN_PROGRESS')
      }

      setIsSyncModalOpen(false)
    }

    if (isErrorSubmitSyncAll) {
      let errorMsg =
        errorSubmitSyncAll.message || // Exact error message from the API (if any)
        'Failed to start sync all. Please try again.' // Fallback generic error message

      message.error(errorMsg)
      setIsSyncModalOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingSubmitSyncAll])

  return (
    <>
      {/* Title */}
      <Title
        level={3}
        data-testid='accrual-manager-header'
        data-cy='accrual-manager-header'
        style={{ marginBottom: 15 }}
      >
        Accrual Manager
      </Title>

      {/* Card */}
      <Card
        data-testid='accrual-manager-card'
        data-cy='accrual-manager-card'
        bodyStyle={{ display: 'flex', gap: 75 }}
      >
        <Text>
          Sync All Lines
          <TooltipIcon
            data-testid='sync-all-tooltip'
            data-cy='sync-all-tooltip'
            message='Use this action to sync all Accrual Manager line data to match the source system.'
          />
        </Text>
        <Space direction='vertical'>
          <SyncModal
            isLoadingSubmitSyncAll={isLoadingSubmitSyncAll}
            isSyncModalOpen={isSyncModalOpen}
            setIsSyncModalOpen={setIsSyncModalOpen}
            handleSyncAll={handleSyncAll}
          />
          <Space align='start'>
            {SYNC_STATUSES[syncStatus!]?.icon}
            <Space direction='vertical'>
              <Text
                strong
                data-testid='succesfully-synced-text'
                data-cy='succesfully-synced-text'
              >
                {SYNC_STATUSES[syncStatus!]?.label}
              </Text>
              <Text
                type='secondary'
                data-testid='last-sync-text'
                data-cy='last-sync-text'
              >
                Last Sync: {formattedDate}
              </Text>

              {/* TODO: Sync Details should trigger a modal on click */}
              {syncStatus !== 'IN_PROGRESS' && (
                <Button
                  type='link'
                  style={{ color: '#725bb4', padding: 0 }}
                  data-testid='sync-details-button'
                  data-cy='sync-details-button'
                >
                  Sync Details
                </Button>

                // TODO: Uncomment this when the SyncDetailsModal is ready
                // <SyncDetailsModal />
              )}
            </Space>
          </Space>
        </Space>
      </Card>
    </>
  )
}

export default AccrualManager
