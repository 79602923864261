/**
 * List of available feature flags w/ their corresponding keys from the API
 * @enum
 */
export enum FEATURE_FLAGS {
  berlin = 'v2.5.0_berlin',
  capeTown = 'v2.6.0_cape_town',
  dublin = 'v2.7.0_dublin',
  edinburgh = 'v2.8.0_edinburgh', // Flag for Q3 2024
  edinburgh_period_management = 'v2_8_0_edinburgh_period_management', // Flag for Period Management
  edinburgh_close_manager = 'v2_8_0_edinburgh_close_manager', // Flag for Close Manager
  edinburgh_accrual_form_link = 'v2_8_0_edinburgh_accrual_form_link', // (TEMPORARY) Flag for Accrual Form Link
  edmonton = 'v2.8.1_edmonton', // Flag for Dynamic Review Center
  etivac = 'v2.8.2_etivac', // Flag for Consolidated flag disabled API (IDMS or Settings)
  integration = 'v1.3.0_integration',
  saml = 'sso_saml',
  sync_all = 'sync_all'
}
