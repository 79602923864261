/** Antd Imports */
import { Alert, Button, Modal, Spin, Typography } from 'antd'
import { LoadingOutlined, SyncOutlined } from '@ant-design/icons'

/** Store */
import useAccrualManagerStore from '../manager/accrual-manager-store'

/** Utils */
import isGappifyAdministrator from 'src/pages/Settings/utils/isGappifyAdministrator'
import isSystemAdministrator from 'src/pages/Settings/utils/isSystemAdministrator'
import isCloseManager from 'src/pages/Settings/utils/isCloseManager'

type Props = {
  isLoadingSubmitSyncAll: boolean
  isSyncModalOpen: boolean
  setIsSyncModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleSyncAll: () => void
}

const { Text } = Typography

const SyncModal = ({
  isLoadingSubmitSyncAll,
  isSyncModalOpen,
  setIsSyncModalOpen,
  handleSyncAll
}: Props) => {
  /** Hooks */
  const syncStatus = useAccrualManagerStore((state) => state.syncStatus)

  const showModal = () => {
    setIsSyncModalOpen(true)
  }

  const handleCancel = () => {
    setIsSyncModalOpen(false)
  }

  const userHasPermission =
    isGappifyAdministrator() || isSystemAdministrator() || isCloseManager()

  const OkButtonLabel = () => (
    <>
      <span>Confirm</span>
      {isLoadingSubmitSyncAll && (
        <Spin
          indicator={
            <LoadingOutlined
              style={{ fontSize: 12, color: 'white', marginLeft: 5 }}
              spin
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            />
          }
        />
      )}
    </>
  )

  return (
    <>
      <Button
        type='primary'
        data-testid='sync-all-button'
        data-cy='sync-all-button'
        icon={
          <SyncOutlined
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          />
        }
        onClick={showModal}
        disabled={syncStatus === 'IN_PROGRESS' || !userHasPermission}
      >
        Sync
      </Button>

      <Modal
        title='Sync All Lines'
        open={isSyncModalOpen}
        onOk={handleSyncAll}
        onCancel={handleCancel}
        cancelButtonProps={{ disabled: isLoadingSubmitSyncAll }}
        bodyStyle={{ display: 'flex', flexDirection: 'column', gap: 20 }}
        okText={<OkButtonLabel />}
        okButtonProps={
          {
            'data-testid': 'modal-message',
            'data-cy': 'modal-message',
            disabled: isLoadingSubmitSyncAll
          } as any
        }
        data-testid='sync-all-modal'
        data-cy='sync-all-modal'
      >
        <Text
          style={{ color: '#777' }}
          data-testid='sync-all-modal-message'
          data-cy='sync-all-modal-message'
        >
          Would you like to sync all data on Accrual Manager to match source
          vendors and source purchase orders? Click 'Confirm' to proceed.{' '}
        </Text>

        <Alert
          data-testid='sync-all-modal-warning'
          data-cy='sync-all-modal-warning'
          message='Warning: Any edits previously made in Accrual Manager will be overridden by this sync action. This action cannot be undone.'
          type='warning'
        />
      </Modal>
    </>
  )
}

export default SyncModal
