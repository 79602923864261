/** React Imports */
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

/** Hooks */
import useSettingsStore from '../../manager/settings-store'
import useIsFlagEnabled from 'src/hooks/useIsFlagEnabled'

/** Components */
import AccrualManager from './components/AccrualManager'

/** Constants */
import { FEATURE_FLAGS } from 'src/features/Flag/constants'

/** Styles */
import styles from './index_q3.module.css'

/** Utils */
import isGappifyAdministrator from '../../utils/isGappifyAdministrator'
import isSystemAdministrator from '../../utils/isSystemAdministrator'
import isCloseManager from '../../utils/isCloseManager'

const CompanySettings = () => {
  /** Hooks */
  const setHistoryApi = useSettingsStore((state) => state.setHistoryApi)
  const isFlagEnabled = useIsFlagEnabled()
  const navigate = useNavigate()

  const userHasPermission =
    isGappifyAdministrator() || isSystemAdministrator() || isCloseManager()

  useEffect(() => {
    // TODO: integration of Audit Trail
    setHistoryApi(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // TODO: remove checking for `sync_all` flag once this page can contain more sections
    if (!userHasPermission || !isFlagEnabled(FEATURE_FLAGS.sync_all)) {
      navigate('/error?message=Forbidden&status=403')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.container}>
      {/* TODO: remove checking for `sync_all` flag once this page can contain more sections */}
      {isFlagEnabled(FEATURE_FLAGS.sync_all) && <AccrualManager />}
    </div>
  )
}

export default CompanySettings
