export default function copyToClipboard(
  text: string,
  onSuccess: () => void,
  onError: (err: any) => void
) {
  if (navigator.clipboard && window.isSecureContext) {
    // Navigator clipboard API method
    navigator.clipboard
      .writeText(text)
      .then(onSuccess)
      .catch((err) => onError(err))
  } else {
    // Fallback method using an off-screen textarea
    let textArea = document.createElement('textarea')
    textArea.value = text

    // Avoid scrolling to bottom
    textArea.style.top = '0'
    textArea.style.left = '0'
    textArea.style.position = 'fixed'
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()
    try {
      document.execCommand('copy')
      onSuccess()
    } catch (err) {
      onError(err)
    }
    document.body.removeChild(textArea)
  }
}
