import {
  Button,
  Col,
  Form,
  FormInstance,
  Input,
  message,
  Row,
  Select,
  Tooltip,
  Typography,
  Upload,
  UploadProps
} from 'antd'
import { UploadOutlined } from '@ant-design/icons'

import moment from 'moment'

import TooltipIcon from '../../TxnEnableFeatures/components/TooltipIcon'
import CopyBtn from './CopyBtn'

import styles from './SSOForm.module.css'

import {
  CERTIFICATE_EXTENSIONS,
  FORM_FIELDS,
  OPTIONS_SIGNING_CERTIFICATE,
  OPTIONS_SSO_BINDING,
  OPTIONS_SSO_TYPE
} from '../constants'

import { isValidUrl } from 'src/utils/regexValidators'

import { TFormData } from '../types'

type TSSOForm = {
  form: FormInstance<any>
  onFinish: (values: any) => void
  onValuesChange: (changedValues: any, allValues: any) => void
  onCancel: (values: any) => void
  onTest: (values: any) => void
  disabled?: boolean
  initialValues?: TFormData
  isEditMode: boolean
  setIsEditMode: (value: boolean) => void
  isLoading?: boolean
  setIsHistoryEnabled: (value: boolean) => void
  isTestDisabled: boolean
}

const { Text } = Typography

const ROW_GUTTER = 16

const urlValidator = (rule: any, value: any) => {
  return isValidUrl(value)
    ? Promise.resolve()
    : Promise.reject(new Error('Invalid url format.'))
}

const getValidityPeriod = (timestamp: string) => {
  let thisLabel = ''
  if (timestamp === '0000-00-00 00:00:00') {
    // Edge case for invalid timestamp format
    thisLabel = 'Invalid timestamp format.'
  } else {
    // Full validity period label
    thisLabel = `Valid until ${moment(timestamp).format('MMMM D, YYYY')}`
  }

  return (
    <span className={isCertExpired(timestamp) ? styles.expired : ''}>
      {thisLabel}
    </span>
  )
}

const isCertExpired = (timestamp: string) => {
  const timeNow = moment()
  const timeValidity = moment(timestamp)
  return timeNow > timeValidity
}

const getTestButtonTooltip = (
  isTestDisabled: boolean,
  isCertExpired: boolean
) => {
  let tooltip = ''

  if (isTestDisabled) {
    tooltip = 'Please complete your SSO setup.'
  } else if (isCertExpired) {
    tooltip = 'Please upload a new certificate.'
  }

  return tooltip
}

const SSOForm = ({
  form,
  onFinish,
  onValuesChange,
  onCancel,
  onTest,
  disabled,
  initialValues,
  isEditMode,
  setIsEditMode,
  isLoading,
  setIsHistoryEnabled,
  isTestDisabled
}: TSSOForm) => {
  const validityPeriod = form.getFieldValue('valid_to')
  const signingCertificate = form.getFieldValue('upload_file')

  /**
   * ? This function is from Antd's documentation. Modify as needed.
   * ? Reference: https://4x.ant.design/components/form/#components-form-demo-validate-other
   */
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  const uploadProps: UploadProps = {
    beforeUpload: (file) => {
      const fileExtension = `.${file.name.split('.').pop()?.toLowerCase()}`
      const isAcceptedExtension = CERTIFICATE_EXTENSIONS.includes(fileExtension)
      if (!isAcceptedExtension) {
        message.error(
          `File type not supported. Please upload one of the following: ${CERTIFICATE_EXTENSIONS.join(
            ', '
          )}`,
          4 // Duration (in seconds) of error toast
        )
        return Upload.LIST_IGNORE
      }
      return false // Prevent upload on upload
    }
  }

  return (
    <>
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        disabled={disabled}
        initialValues={initialValues}
        data-cy='sso-form'
        data-testid='sso-form'
      >
        <div className={styles.outerContainer}>
          <div className={styles.innerContainer}>
            <Row gutter={ROW_GUTTER}>
              <Col span={12}>
                <Form.Item
                  label={
                    <>
                      {FORM_FIELDS.SSO_TYPE.label}
                      <TooltipIcon message={FORM_FIELDS.SSO_TYPE.tooltip} />
                    </>
                  }
                  name={FORM_FIELDS.SSO_TYPE.name}
                  initialValue={FORM_FIELDS.SSO_TYPE.initialValue}
                  rules={[
                    {
                      required: true,
                      message: FORM_FIELDS.SSO_TYPE.requiredTxt
                    }
                  ]}
                >
                  <Select options={OPTIONS_SSO_TYPE} loading={isLoading} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={
                    <>
                      {FORM_FIELDS.ENTITY_ID.label}
                      <TooltipIcon message={FORM_FIELDS.ENTITY_ID.tooltip} />
                    </>
                  }
                  name={FORM_FIELDS.ENTITY_ID.name}
                  rules={[
                    {
                      required: true,
                      message: FORM_FIELDS.ENTITY_ID.requiredTxt
                    }
                  ]}
                >
                  <Input disabled className={styles.disabledInput} />
                </Form.Item>
                <CopyBtn
                  form={form}
                  field={FORM_FIELDS.ENTITY_ID.name}
                  label={FORM_FIELDS.ENTITY_ID.label}
                />
              </Col>
            </Row>

            <Row gutter={ROW_GUTTER}>
              <Col span={12}>
                <Form.Item
                  label={
                    <>
                      {FORM_FIELDS.SSO_BINDING.label}
                      <TooltipIcon message={FORM_FIELDS.SSO_BINDING.tooltip} />
                    </>
                  }
                  name={FORM_FIELDS.SSO_BINDING.name}
                  initialValue={FORM_FIELDS.SSO_BINDING.initialValue}
                  rules={[
                    {
                      required: true,
                      message: FORM_FIELDS.SSO_BINDING.requiredTxt
                    }
                  ]}
                >
                  <Select options={OPTIONS_SSO_BINDING} loading={isLoading} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={
                    <>
                      {FORM_FIELDS.ACS_ENDPOINT.label}
                      <TooltipIcon message={FORM_FIELDS.ACS_ENDPOINT.tooltip} />
                    </>
                  }
                  name={FORM_FIELDS.ACS_ENDPOINT.name}
                  rules={[
                    {
                      required: true,
                      message: FORM_FIELDS.ACS_ENDPOINT.requiredTxt
                    }
                  ]}
                >
                  <Input disabled className={styles.disabledInput} />
                </Form.Item>
                <CopyBtn
                  form={form}
                  field={FORM_FIELDS.ACS_ENDPOINT.name}
                  label={FORM_FIELDS.ACS_ENDPOINT.label}
                />
              </Col>
            </Row>

            <Row gutter={ROW_GUTTER}>
              <Col span={12}>
                <Form.Item
                  label={
                    <>
                      {FORM_FIELDS.SIGNING_CERTIFICATE.label}
                      <TooltipIcon
                        message={FORM_FIELDS.SIGNING_CERTIFICATE.tooltip}
                      />
                    </>
                  }
                  name={FORM_FIELDS.SIGNING_CERTIFICATE.name}
                  initialValue={FORM_FIELDS.SIGNING_CERTIFICATE.initialValue}
                  rules={[
                    {
                      required: true,
                      message: FORM_FIELDS.SIGNING_CERTIFICATE.requiredTxt
                    }
                  ]}
                >
                  <Select
                    options={OPTIONS_SIGNING_CERTIFICATE}
                    loading={isLoading}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name={FORM_FIELDS.UPLOAD.name}
                  valuePropName='fileList'
                  getValueFromEvent={normFile}
                  style={{ marginTop: 31 }} // Needed since this button does not have a form item label from the UI design
                  rules={[
                    {
                      required: true,
                      message: FORM_FIELDS.UPLOAD.requiredTxt
                    }
                  ]}
                >
                  <Upload
                    {...uploadProps}
                    accept={CERTIFICATE_EXTENSIONS.join(',')}
                    listType='text'
                    maxCount={1}
                    itemRender={(originNode, file, currFileList) => {
                      return (
                        <div
                          style={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden'
                          }}
                        >
                          {file.name}
                        </div>
                      )
                    }}
                  >
                    <Button
                      icon={
                        <UploadOutlined
                          onPointerEnterCapture={() => {}}
                          onPointerLeaveCapture={() => {}}
                        />
                      }
                      loading={isLoading}
                    >
                      {FORM_FIELDS.UPLOAD.label}
                    </Button>
                  </Upload>
                </Form.Item>
                <Form.Item name='valid_to' hidden>
                  <Input />
                </Form.Item>
                {validityPeriod && signingCertificate && (
                  <Text
                    className={styles.validityPeriod}
                    type='secondary'
                    italic
                  >
                    {getValidityPeriod(validityPeriod)}
                  </Text>
                )}
              </Col>
            </Row>

            <Row gutter={ROW_GUTTER}>
              <Col span={24}>
                <Form.Item
                  label={<>{FORM_FIELDS.METADATA_URL.label}</>}
                  name={FORM_FIELDS.METADATA_URL.name}
                  rules={[
                    {
                      required: true,
                      message: FORM_FIELDS.METADATA_URL.requiredTxt
                    },
                    {
                      validator: urlValidator
                    }
                  ]}
                >
                  <Input disabled={isLoading} />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>

        {isEditMode && (
          <div className={styles.buttonContainer}>
            <Button
              className={styles.button}
              onClick={onCancel}
              data-cy='sso-form-btn-cancel'
              data-testid='sso-form-btn-cancel'
            >
              Cancel
            </Button>
            <Button
              type='primary'
              htmlType='submit'
              className={styles.button}
              data-cy='sso-form-btn-save'
              data-testid='sso-form-btn-save'
            >
              Save
            </Button>
          </div>
        )}
      </Form>

      {!isEditMode && (
        <div className={styles.buttonContainer}>
          <Tooltip
            title={getTestButtonTooltip(
              isTestDisabled,
              isCertExpired(validityPeriod)
            )}
            placement='top'
          >
            <div>
              <Button
                className={styles.button}
                onClick={onTest}
                data-cy='sso-form-btn-test'
                data-testid='sso-form-btn-test'
                disabled={isTestDisabled || isCertExpired(validityPeriod)}
              >
                Test
              </Button>
            </div>
          </Tooltip>
          <Button
            type='primary'
            className={styles.button}
            onClick={() => {
              setIsEditMode(true)
              setIsHistoryEnabled(false)
            }}
            data-cy='sso-form-btn-edit'
            data-testid='sso-form-btn-edit'
          >
            Edit
          </Button>
        </div>
      )}
    </>
  )
}

export default SSOForm
