/** 3rd party imports */
import { DownOutlined, ExportOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import download from 'downloadjs'

/** react imports */
import { useEffect, useRef, useState } from 'react'

/** custom components imports */
import Filter from 'src/components/filteringNew'
import GDropdownBtn from 'src/components/gappify/GDropdownBtn'
import LogRecordModal from './components/LogRecordModal'
import LogsTable from './components/LogsTable'

/** hooks imports */
import useExportCsvLog from './hooks/useExportCsvLog'
import useFetchColumnHeaders from './hooks/useFetchColumnHeaders'
import useLogsFlag from './hooks/useLogsFlag'

/** zustand store imports */
import useLogsTableStore from './stores/useLogsTableStore'

/** types imports */
import DropdownEvent from './types/DropdownEvent'

// =================================================================
export default function Logs() {
  /** refs */
  const activeFilterRef = useRef({
    id: 'all',
    label: 'All Lines',
    tempPreviewId: 0
  })

  /** hooks */
  useLogsFlag()
  const queryClient = useQueryClient()
  const { refetch: exportLogCsv } = useExportCsvLog()
  const { data: columnHeaders } = useFetchColumnHeaders()

  /** local states */
  const [isExportloading, setIsExportloading] = useState(false)

  /** zustand store */
  const page = useLogsTableStore((state) => state.page)
  const per_page = useLogsTableStore((state) => state.per_page)
  const sort = useLogsTableStore((state) => state.sort)
  const setPage = useLogsTableStore((state) => state.setPage)
  const setSort = useLogsTableStore((state) => state.setSort)
  const setPreviewMode = useLogsTableStore((state) => state.setPreviewMode)
  const setCustomFilter = useLogsTableStore((state) => state.setCustomFilter)

  /** functions */
  const handleOnClickExport = async ({ key }: DropdownEvent) => {
    setIsExportloading(true)
    if (key === 'csv') {
      const date = new Date().toISOString().slice(0, 10)

      const result = await exportLogCsv()

      download(result.data, `logs-${date}.csv`, 'csv')
    }
    setIsExportloading(false)
  }

  useEffect(() => {
    return () => {
      setPage(1)
      setPreviewMode(false)
      setCustomFilter('all')
      setSort({
        'sort[status]': 'desc',
        'sort[created_at]': 'desc'
      })
    }
  }, [setCustomFilter, setPage, setPreviewMode, setSort])

  /** jsx */
  return (
    <>
      <div
        className='table-content-wrapper'
        data-testid='integrations-logs-page'
        data-cy='integrations-logs-page'
      >
        <div
          style={{
            marginBottom: '20px',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <div style={{ display: 'flex' }}>
            <Filter
              microservice='gappify-api'
              activeFilterRef={activeFilterRef}
              loadDataCallback={(isPreviewMode) => {
                setPreviewMode(isPreviewMode)
                setPage(1)
                setSort({
                  'sort[status]': 'desc',
                  'sort[created_at]': 'desc'
                })

                const { id } = activeFilterRef.current

                // if preview mode is true, use the id of the active filter, else use 'all'
                const customFilter = () => {
                  if (!isPreviewMode && Number(id) > 0) {
                    return id
                  }
                  return 'all'
                }
                setCustomFilter(customFilter())

                // refetch data
                queryClient.invalidateQueries({
                  queryKey: [
                    'integration-logs',
                    page,
                    per_page,
                    sort,
                    isPreviewMode,
                    customFilter()
                  ]
                })
              }}
              getSavedFiltersUrl='api/logs/filters'
              getFieldsUrl='api/log/filters/all-filter-fields'
              getFilterByIdUrl='api/logs/filters'
              createFilterUrl='api/logs/filters'
              updateFilterUrl='api/logs/filters'
              deleteFilterUrl='api/logs/filters'
              previewFilterUrl='api/logs/filters/preview'
              allColumnHeaders={columnHeaders.fields}
            />
          </div>

          <GDropdownBtn
            dataTestId='export-btn'
            dataCy='export-btn'
            menuItems={[
              {
                label: (
                  <span
                    data-testid='integ-export-csv-btn'
                    data-cy='integ-export-csv-btn'
                  >
                    CSV
                  </span>
                ),
                key: 'csv'
              }
            ]}
            btnText='Export to'
            btnIcon={
              <ExportOutlined
                onPointerEnterCapture={() => {}}
                onPointerLeaveCapture={() => {}}
              />
            }
            children={
              <DownOutlined
                onPointerEnterCapture={() => {}}
                onPointerLeaveCapture={() => {}}
              />
            }
            className='g-btn-default'
            onClick={handleOnClickExport}
            loading={isExportloading}
          />
        </div>
        <LogsTable />
        <LogRecordModal />
      </div>
    </>
  )
}
