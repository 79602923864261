// ** Zustand Imports
import { create } from 'zustand'

// ** Types
import AccrualManagerProps from '../types/AccrualManagerProps'

const useAccrualManagerStore = create<AccrualManagerProps>((set, get) => ({
  // Define initial state
  syncStatus: undefined,

  // Define actions
  setSyncStatus: (syncStatus: string | undefined) => set({ syncStatus })
}))

export default useAccrualManagerStore
