import { useMutation } from '@tanstack/react-query'
import useApiRequest from 'src/hooks/useApiRequest'
import useApiService from 'src/hooks/useApiService'

type Response = Record<string, any>
type Params = Record<string, any>

type Error = {
  data: {
    errors: Record<string, string[]>
    message: string
  }
}

const useSubmitSSOForm = () => {
  const { globalApi } = useApiRequest()
  const { msUrl } = useApiService()

  return useMutation<Response, Error, Params>({
    mutationFn: ({ payload }) =>
      globalApi({
        options: {
          baseUrl: msUrl.settings,
          endpoint: `/api/sso/update-user-pool`,
          method: 'POST',
          customHeaders: {
            'Content-Type': 'multipart/form-data'
          }
        },
        data: payload
      })
  })
}

export default useSubmitSSOForm
