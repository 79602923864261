// ** React **
import { useEffect, useState } from 'react'

// ** AntD **
import { Spin } from 'antd'

// ** Components **
import TaskManagerTable from '../TaskManagerTable'

// ** Hooks **
import useIsFlagEnabled from 'src/hooks/useIsFlagEnabled'
import useFetchSyncAllStatus from 'src/hooks/useFetchSyncAllStatus'

// ** Constants **
import { FEATURE_FLAGS } from 'src/features/Flag/constants'

// ** Styles **
import styles from './index.module.css'

const AccrualManager = () => {
  const isFlagEnabled = useIsFlagEnabled()
  const isSyncAllEnabled = isFlagEnabled(FEATURE_FLAGS.sync_all)

  /** Local states */
  const [isSyncing, setIsSyncing] = useState(true)

  /** React queries */
  const {
    data: dataFetchSyncAllStatus,
    error: errorFetchSyncAllStatus,
    isLoading: isLoadingFetchSyncAllStatus,
    isSuccess: isSuccessFetchSyncAllStatus,
    isError: isErrorFetchSyncAllStatus
  } = useFetchSyncAllStatus()

  useEffect(() => {
    if (isSuccessFetchSyncAllStatus) {
      // Set sync status based on the response
      setIsSyncing(dataFetchSyncAllStatus.is_syncing)
    }

    if (isErrorFetchSyncAllStatus) {
      /**
       * ? DEV NOTES:
       * ? No expected error scenarios for this query
       * ? Add error handling here if needed
       */
      console.log('Error response from API:', errorFetchSyncAllStatus)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingFetchSyncAllStatus])

  if (!isLoadingFetchSyncAllStatus) {
    if (isSyncAllEnabled && isSyncing) {
      return (
        <>
          {/* Gray background */}
          <div className={styles.outerContainer}></div>

          {/* Sync all in progress content */}
          <div className={styles.innerContainer}>
            <div className={styles.contentContainer}>
              Sync is currently in progress. The Accrual Manager page will
              become available once the sync process is completed. Please check
              back shortly.
            </div>
          </div>
        </>
      )
    } else {
      return <TaskManagerTable />
    }
  }

  /**
   * ? DEV NOTES:
   * ? Initial loading guard rails when fetching sync all status
   * ? Since the sync status API is now enabled, the initial value of isLoadingFetchSyncAllStatus = true
   */
  return (
    <div style={{ textAlign: 'center', margin: 18 }}>
      <Spin />
    </div>
  )
}

export default AccrualManager
