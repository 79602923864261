/** antd imports */
import { Button, Spin } from 'antd'

/** react imports */
import { useEffect } from 'react'

/** custom component imports */
import AzureModal from './AzureModal'
import LoginGoogleIcon from './LoginGoogleIcon'
import OktaModal from './OktaModal'
import SamlModal from './SamlModal'

/** hooks imports */
import useGoogleLogin from '../hooks/useGoogleLogin'

import { FEATURE_FLAGS } from 'src/features/Flag/constants'
import useIsFlagEnabled from 'src/hooks/useIsFlagEnabled'
import useFlagStore from 'src/features/Flag/stores/flagStore'
import { LoadingOutlined } from '@ant-design/icons'

// =================================================================
export default function LoginSSO() {
  /** hooks */
  const {
    data: googleLoginData,
    refetch: onClickGoogleSignin,
    isSuccess: isGoogleLoginSuccess,
    isFetching
  } = useGoogleLogin()
  const isFlagEnabled = useIsFlagEnabled()

  const isFlagsLoading = useFlagStore((state) => state.isFlagsLoading)

  useEffect(() => {
    if (isGoogleLoginSuccess) {
      window.location.replace(googleLoginData)
    }
  }, [googleLoginData, isGoogleLoginSuccess])

  return (
    <div
      style={{
        marginTop: '50px'
      }}
    >
      {/* Temporarily display spinner while /next-flag is loading */}
      {isFlagsLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '100px'
          }}
        >
          <div>
            <Spin
              indicator={
                <LoadingOutlined
                  spin
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                />
              }
            />
          </div>
        </div>
      ) : (
        <>
          <Button
            data-test-id='login-google-btn'
            data-cy='login-google-btn'
            className='google-btn'
            type='primary'
            icon={<LoginGoogleIcon />}
            onClick={() => onClickGoogleSignin()}
            loading={isFetching}
          >
            SIGN IN WITH GOOGLE
          </Button>
          <OktaModal />
          <AzureModal />

          {/* If this checking will be removed, also remove spinner */}
          {isFlagEnabled(FEATURE_FLAGS.saml) && <SamlModal />}
        </>
      )}
    </div>
  )
}
