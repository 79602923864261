import * as Sentry from '@sentry/react'
import getGenericEnv from './utils/getGenericEnv'

const hostname = window.location.hostname
const sentryEnv = getGenericEnv(hostname).sentry_env

console.log('SENTRY ENV: ', sentryEnv, hostname) //will delete after verification

Sentry.init({
  dsn: 'https://21675bbbe66d13a240f63853ac8fef6b@o4507733487583232.ingest.us.sentry.io/4507733507244032',
  environment: sentryEnv,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration()
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})
