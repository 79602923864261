/** antd imports */
import { Form, Input, message } from 'antd'

/** react imports */
import { useEffect } from 'react'

/** custom component imports */
import GModal from '../../../components/gappify/GModal'
import LoginAzureIcon from './LoginAzureIcon'

/** hooks imports */
import useLoginAzure from '../hooks/useLoginAzure'

/** zustand imports */
import useLoginStore from '../manager/useLoginStore'

/** redux imports */
import setError from '../../../context/actions/errorAction'
import store from './../../../context/store'

// =================================================================
export default function AzureModal() {
  /** antd */
  const [form] = Form.useForm()

  /** zustand states */
  const { azureEmail, setAzureEmail } = useLoginStore()

  /** hooks */
  const {
    refetch: handleSubmit,
    isError,
    isSuccess,
    data,
    error
  } = useLoginAzure({ email: azureEmail })

  useEffect(() => {
    if (isSuccess) {
      window.location.replace(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  useEffect(() => {
    if (isError) {
      store.dispatch(
        setError({
          visible: false,
          guid: '',
          status: 0,
          message: ''
        })
      )
      message.error(error.data.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  const handleCancel = () => {
    console.log('close')
  }

  return (
    <div className='azure-btn'>
      <GModal
        dataTestId='azure-modal'
        dataCy='azure-modal'
        btnText='AZURE SIGN IN'
        title='Enter your email address'
        btnIcon={<LoginAzureIcon />}
        okButtonProps={{ htmlType: 'submit' }}
        onOk={form.submit}
        onCancel={handleCancel}
        content={
          <Form form={form} onFinish={handleSubmit}>
            <Form.Item
              name='email'
              rules={[
                { required: true, message: 'You need to write something!' }
              ]}
            >
              <Input
                data-testid='azure-email-input-field'
                data-cy='azure-email-input-field'
                placeholder='Enter a valid email address'
                onChange={(event) => {
                  const email = event.target.value
                  setAzureEmail(email)
                }}
              />
            </Form.Item>
          </Form>
        }
      />
    </div>
  )
}
