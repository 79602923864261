// ** React imports
import { useLocation } from 'react-router-dom'

// ** Antd imports
import { Button, Divider, Space, Typography } from 'antd'

// ** Global store
import useSettingsStore from '../manager/settings-store'

// ** Custom components
import GlobalHistoryModal from 'src/components/GlobalHistoryModal'

const SelectedSettingsTitle = () => {
  const { Title, Text } = Typography
  const selectedSettings = useSettingsStore((state) => state.selectedSettings)
  const settingsDescription = useSettingsStore(
    (state) => state.settingsDescription
  )
  const isHistoryEnabled = useSettingsStore((state) => state.isHistoryEnabled)
  const historyColumns = useSettingsStore((state) => state.historyColumns)
  const historyApi = useSettingsStore((state) => state.historyApi)

  const location = useLocation()

  if (location.pathname === '/settings/user-management/sso') {
    /**
     * ! DEV NOTES
     * * SSO page will use a separate component for its title, description and audit button
     * * This is needed so that we can handle the detection of unsaved fields, and properly rendering the SSO title
     * ? See SSOTitle.tsx file
     */
    return <></>
  }

  return (
    <>
      <Space
        style={{ display: 'flex', justifyContent: 'space-between' }}
        data-testid='selected-settings-title'
      >
        <Title level={2} style={{ marginBottom: 0 }}>
          {selectedSettings}
        </Title>
        {isHistoryEnabled && (
          <GlobalHistoryModal
            modalTitle='Audit Trail'
            overrideMainButton={<Button>Audit Trail</Button>}
            columns={historyColumns}
            reloadData={historyApi}
          />
        )}
      </Space>
      {settingsDescription && <Text>{settingsDescription}</Text>}
      <Divider />
    </>
  )
}

export default SelectedSettingsTitle
