import { InfoCircleFilled } from '@ant-design/icons'
import { Tooltip } from 'antd'

type Props = {
  message: string
  'data-testid'?: string
  'data-cy'?: string
}

const TooltipIcon = (props: Props) => {
  const { message } = props

  return (
    <Tooltip title={message}>
      <InfoCircleFilled
        onPointerEnterCapture={() => {}}
        onPointerLeaveCapture={() => {}}
        style={{ marginLeft: 8, color: '#715BB4' }}
        data-testid={props['data-testid']}
        data-cy={props['data-cy']}
      />
    </Tooltip>
  )
}

export default TooltipIcon
