import { useMutation } from '@tanstack/react-query'
import useApiRequest from 'src/hooks/useApiRequest'
import useApiService from 'src/hooks/useApiService'

type Response = Record<string, any>
type Params = Record<string, any>

const useFetchSSOForm = () => {
  const { globalApi } = useApiRequest()
  const { msUrl } = useApiService()

  return useMutation<Response, Error, Params>({
    mutationFn: ({ payload }) =>
      globalApi({
        options: {
          baseUrl: msUrl.settings,
          endpoint: `/api/sso/create-user-pool`,
          method: 'POST'
        },
        data: payload
      })
  })
}

export default useFetchSSOForm
