import { FormInstance, message, Typography } from 'antd'

import copyToClipboard from 'src/utils/copyToClipboard'

import styles from './CopyBtn.module.css'

const { Text } = Typography

type TCopyBtn = {
  form: FormInstance<any>
  field: string
  label: string
}

const CopyBtn = ({ form, field, label }: TCopyBtn) => {
  const onCopyClick = () => {
    const text = form.getFieldValue(field)

    // Run only when text has a value
    if (text) {
      const onSuccess = () => {
        message.success(`Copied ${label}!`)
      }

      const onError = (err: any) => {
        console.log('Error:', err) // Will leave for debugging purpose
        message.error(`Error when copying ${label}! Please try again later.`)
      }

      copyToClipboard(text, onSuccess, onError)
    }
  }

  return (
    <Text className={styles.copy} onClick={onCopyClick}>
      Copy
    </Text>
  )
}

export default CopyBtn
