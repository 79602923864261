/** tanstack imports */
import { useQuery } from '@tanstack/react-query'

/** services imports */
import useApiService from '../../../hooks/useApiService'
import useAuthService from '../../../services/useAuthService'

// =================================================================
export default function useLoginSaml(params: Record<string, string>) {
  /** API services */
  const { authAPI } = useAuthService()
  const { msUrl } = useApiService()

  return useQuery<any, any>({
    queryKey: ['login-saml'],
    queryFn: () =>
      authAPI({
        params,
        options: {
          baseURL: msUrl.idms,
          endpoint: '/login/saml',
          method: 'GET'
        }
      }),
    enabled: false
  })
}
