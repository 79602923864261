import { FC, useEffect } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './context/store'
import { PENDO_METADATA } from './helpers/constants/Auth'
import { pendoInitialize } from './utils/pendo-utils'
import QueryProvider from './providers/QueryProvider'
import publicRoutes from './routes/publicRoutes'

const txtlink: string = '/version.txt'

const App: FC = (): JSX.Element => {
  /**
   * ? Migrate routing from Browser router to the new Data routers
   * ? Ref: https://reactrouter.com/en/main/upgrading/v6-data#add-routerprovider-with-a-root-splat-route
   */
  const router = createBrowserRouter(publicRoutes)

  useEffect(() => {
    const main = () => {
      fetch(txtlink)
        .then((res) => res.text())
        .then((text) => {
          if (!text.includes('<!DOCTYPE html>')) {
            localStorage.setItem('version', text)
          } else {
            localStorage.removeItem('version')
          }
        })
    }
    main()
  }, [])

  useEffect(() => {
    if (localStorage.getItem(PENDO_METADATA)) {
      pendoInitialize(JSON.parse(localStorage.getItem(PENDO_METADATA)!))
    }
  }, [])

  return (
    <QueryProvider>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </QueryProvider>
  )
}

export default App
