// ** React Imports **
import { useEffect, useRef, useState } from 'react'
import { useBlocker, useNavigate } from 'react-router-dom'

// ** AntD Imports **
import { Form, Input, InputRef, message, Modal, Spin } from 'antd'
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons'

// ** Custom Components **
import SSOForm from './components/SSOForm'
import SSOTitle from './components/SSOTitle'

// ** Roles **
import isGappifyAdministrator from '../../utils/isGappifyAdministrator'
import isSystemAdministrator from '../../utils/isSystemAdministrator'

// ** Styles **
import styles from './index.module.css'

// ** Types **
import { TFormData } from './types'

// ** Hooks **
import useFetchSSOForm from './hooks/useFetchSSOForm'
import useSubmitSSOForm from './hooks/useSubmitSSOForm'
import useSubmitSSOTest from './hooks/useSubmitSSOTest'
import useSettingsStore from '../../manager/settings-store'
import { getSSOHistory } from 'src/services/HistoryAPI'

// ** Utils **
import { isValidEmail } from 'src/utils/regexValidators'

import moment from 'moment'

const { confirm } = Modal

// These are fields to skip when checking for unsaved changes
const excludedFields = [
  // Has single default values
  'sso_type',
  'sso_binding',
  'signing_certificate',

  // Certificate only fields
  'valid_to',
  'upload_file'
]

// ========================================================================
const SSO = () => {
  // ** States **
  const [isEditMode, setIsEditMode] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const [isTestDisabled, setIsTestDisabled] = useState(true)
  const [initialValues, setInitialValues] = useState<TFormData>()
  const ssoTestEmailInput = useRef<InputRef>(null)

  // No need to use the global state, as this is only for SSO page to handle the history modal of SSOTitle.tsx
  const [isHistoryEnabled, setIsHistoryEnabled] = useState(true)

  const buttonRef = useRef<string>()

  // ** R&P **
  const userHasSSOPermission: boolean =
    isGappifyAdministrator() || isSystemAdministrator()

  // ** Hooks **
  const [form] = Form.useForm()
  const [formTest] = Form.useForm()
  const navigate = useNavigate()
  const setHistoryApi = useSettingsStore((state) => state.setHistoryApi)

  // Block navigating elsewhere with the following criteria:
  let blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return (
      // If form has unsaved changes
      isDirty &&
      // If current page is not the same as the next page
      currentLocation.pathname !== nextLocation.pathname
    )
  })

  // ** React Query **
  const {
    mutate: fetchSsoSettings,
    isLoading: isLoadingSsoSettings,
    isError: isErrorSsoSettings,
    data: ssoSettings
  } = useFetchSSOForm()

  const {
    mutate: submitSsoForm,
    error: errorSsoFormSubmission,
    isLoading: isLoadingSsoFormSubmission,
    isSuccess: isSuccessSsoFormSubmission,
    isError: isErrorSsoFormSubmission
  } = useSubmitSSOForm()

  const {
    mutate: submitSsoTest,
    error: errorSsoTestSubmission,
    isLoading: isLoadingSsoTest,
    isSuccess: isSuccessSsoTest,
    isError: isErrorSsoTest
  } = useSubmitSSOTest()

  // ** Helper **
  const emailValidator = (rule: any, value: any) => {
    return isValidEmail(value)
      ? Promise.resolve()
      : Promise.reject(
          new Error('Please provide a properly formatted email address.')
        )
  }

  const focusEmailInput = () => {
    if (ssoTestEmailInput.current) {
      ssoTestEmailInput.current.focus()
    }
  }

  const getIsDisabled = () => {
    return (
      !isEditMode ||
      isLoadingSsoSettings ||
      isLoadingSsoFormSubmission ||
      isLoadingSsoTest
    )
  }

  // This function helps update the isDirty state whether the form has unsaved fields or not
  const onValuesChange = (changedValues: TFormData, allValues: TFormData) => {
    if (!initialValues) return

    let hasUnsavedChanges = false

    // Compare all changed values to initial form values
    for (const [key, value] of Object.entries(allValues)) {
      // Skip checking if upload field
      if (!excludedFields.includes(key)) {
        const oldValue = initialValues[key as keyof TFormData]

        if (value !== oldValue) {
          hasUnsavedChanges = true
          break // to early exit the for statement
        }
      }
    }

    // If a new file is detected, remove the 'valid_to' field from the payload
    if (changedValues.upload_file) {
      form.setFieldValue('valid_to', '')
      hasUnsavedChanges = true
    }

    setIsDirty(hasUnsavedChanges)
  }

  const onCancel = () => {
    buttonRef.current = 'discard'
    if (!isDirty) {
      setIsEditMode(false)
      setIsHistoryEnabled(true)
    } else {
      showUnsavedModal(
        () => {
          form.submit()
        },
        () => {
          form.resetFields()
          setIsEditMode(false)
          setIsHistoryEnabled(true)
          setIsDirty(false)
        }
      )
    }
  }

  const onFinish = (values: any) => {
    // Deconstruct form input values
    let {
      entity_id,
      acs_endpoint,
      signing_certificate,
      upload_file,
      idp_metadata
    } = values

    // Construct payload to be passed to the API
    let payload = {
      entity_id,
      acs_endpoint,
      sso_type: 'saml',
      saml_ssobinding: 'HTTP_POST',
      signing_certificate_url: signing_certificate,
      verification_certificate:
        upload_file[0].originFileObj || // Add the new file object if a new certificate is uploaded
        upload_file[0].name, // Add the original file name if no new certificate is uploaded
      user_pool_id: ssoSettings?.data.user_pool_id,
      idp_metadata
    }

    // Call the API
    submitSsoForm({ payload })
  }

  const onModalOk = () => {
    formTest.submit()
  }

  const onModalCancel = () => {
    formTest.resetFields()
    setIsModalVisible(false)
  }

  const onTestStart = () => {
    setIsModalVisible(true)
    setTimeout(() => {
      focusEmailInput()
    }, 200)
  }

  const onTestFinish = (value: any) => {
    submitSsoTest({ email: value.email })
  }

  const populateFormContent = () => {
    // For fetching inital sso form values
    let payload = { sso_type: 'saml', sso_binding: 'HTTP_POST' }
    fetchSsoSettings({ payload })
  }

  const showUnsavedModal = (
    onOkCallback: () => void,
    onCancelCallback: () => void
  ) => {
    confirm({
      title: 'Unsaved changes',
      content: 'You have unsaved changes. Are you sure you want to cancel?',
      centered: true,
      okText: 'Save Changes',
      cancelText: 'Discard',
      onOk: onOkCallback,
      onCancel: () => {
        // If user clicks 'Discard' button, perform cancel action
        if (buttonRef.current === 'discard') {
          onCancelCallback()
        }
        // Else if the user clicks the 'X' button, only close the modal
      },
      closeIcon: (
        <div onClick={() => (buttonRef.current = 'close')}>
          <CloseOutlined
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          />
        </div>
      ),
      closable: true
    })
  }

  useEffect(() => {
    if (!userHasSSOPermission) {
      navigate('/error?message=Forbidden&status=403')
    } else populateFormContent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (ssoSettings) {
      let { data } = ssoSettings
      // construct values to be passed to the form
      let values: TFormData = {
        entity_id: data.entity_id,
        acs_endpoint: data.acs_endpoint,
        /**
         * These 3 fields can be excluded for now since we only have 1 expected value on each dropdowns
         * Must be in synced with the value that BE is expecting
         */
        sso_type: 'saml',
        sso_binding: data.saml_ssobinding,
        signing_certificate: 'PingOne SSO Certificate',
        idp_metadata: data.idp_metadata
      }

      if (data.verification_certificate && data.valid_to) {
        const isCertExpired = moment() > moment(data.valid_to)
        const fileName = !isCertExpired ? data.verification_certificate : ''

        /**
         * Initialize uploaded certificate
         * ? Ref: https://4x.ant.design/components/upload/#components-upload-demo-defaultFileList
         */
        values.upload_file = [
          {
            uid: '1', // unique identifier for the file
            name: fileName, // file name
            status: 'done', // status of the upload, 'done' means finished
            url: '#' // URL to the file
          }
        ]
        /**
         * This is a timestamp that will be generated by the BE
         * This will come from the uploaded certificate
         *
         * !DEV NOTES:
         * When uploading new certificate, this field will be removed from the onValuesChange function
         * BE will generate a new timestamp from the new uploaded certificate
         */
        values.valid_to = data.valid_to
      }

      /**
       * Loop through the fields and check if there is at lease 1 empty/undefined value
       * If there is, the Test button should be disabled
       */
      const isDisabled = Object.values(values).some(
        (value) => value === '' || value === undefined || value === null
      )
      setIsTestDisabled(isDisabled)

      setInitialValues(values)
      form.setFieldsValue(values)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ssoSettings])

  // Catch error when fetching SSO settings failed...
  useEffect(() => {
    if (isErrorSsoSettings) {
      // Handle error, e.g., show an error message, log the error, etc.
      message.error('Failed to fetch SSO settings. Please try again.')
    }
  }, [isErrorSsoSettings])

  // Handle success on form submission...
  useEffect(() => {
    if (isSuccessSsoFormSubmission) {
      // Handle success, e.g., show a success message, update UI, etc.
      message.success('SSO settings saved.')
      populateFormContent()
      setIsEditMode(false)
      setIsHistoryEnabled(true)
      setIsDirty(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessSsoFormSubmission])

  // Catch error when form submission method failed...
  useEffect(() => {
    if (isErrorSsoFormSubmission) {
      let errorMsg = ''

      const errors = errorSsoFormSubmission?.data?.errors
      if (errors) {
        // Get the exact error message if available
        // Can only display the first error
        errorMsg = Object.values(errors)[0][0]
      } else {
        // Else, display a generic error message
        errorMsg = 'Failed to update SSO settings. Please try again.'
      }

      message.error(errorMsg)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorSsoFormSubmission])

  // Handle trigger of Test button
  useEffect(() => {
    if (isSuccessSsoTest) {
      message.success('SSO settings test successful.')
      formTest.resetFields()
      setIsModalVisible(false)
    }

    if (isErrorSsoTest) {
      let errorMsg = ''

      const errors = errorSsoTestSubmission?.data?.errors
      if (errors) {
        // Get the exact error message if available
        // Can only display the first error
        errorMsg = Object.values(errors)[0][0]
      } else {
        // Else, display a generic error message
        errorMsg = 'Failed to test SSO settings. Please try again.'
      }

      message.error(errorMsg)
      focusEmailInput()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingSsoTest])

  // Update history API when SSO settings are fetched
  // This is only for SSO page
  useEffect(() => {
    if (ssoSettings) {
      setHistoryApi(() => getSSOHistory(ssoSettings.data.id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ssoSettings])

  /**
   * This useEffect detects whether the form has unsaved fields
   * The event listener is then triggered and tested on the following actions:
   *    - when user refresh the page
   *    - when user visits a different url on the same tab
   *    - when user closes the tab
   *    - when user closes the browser
   *    - when user shutdowns the device
   * ? Ref: https://www.youtube.com/watch?v=K8YShjU5PBQ
   */
  // !DEV NOTES:
  // * Uncomment below if the above events are required
  // useEffect(() => {
  //   if (!isDirty) return

  //   const handleOnBeforeUnload = (e: BeforeUnloadEvent) => {
  //     e.preventDefault()
  //     return (e.returnValue = true)
  //   }

  //   window.addEventListener('beforeunload', handleOnBeforeUnload, {
  //     capture: true
  //   })

  //   return () => {
  //     window.removeEventListener('beforeunload', handleOnBeforeUnload, {
  //       capture: true
  //     })
  //   }
  //   // eslint-disable-next-line
  // }, [isDirty])

  useEffect(() => {
    if (blocker.state === 'blocked') {
      buttonRef.current = 'discard'
      showUnsavedModal(
        () => {
          // Cancel navigation and proceed with form submission
          blocker.reset && blocker.reset()
          form.submit()
        },
        () => {
          // Add slight delay to allow closing of modal first before navigating to the next location
          setTimeout(() => {
            // Proceed with navigating to the next location
            blocker.proceed && blocker.proceed()
          }, 200)
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocker])

  return (
    <>
      {/* This title is only for SSO as this page will not use the global SettingsTitle component */}
      <SSOTitle isHistoryEnabled={isHistoryEnabled} />

      {/* Main content */}
      <div className={styles.container}>
        {isLoadingSsoSettings ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '100px'
            }}
          >
            <div>
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 48, color: 'gray' }}
                    spin
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  />
                }
              />
            </div>
          </div>
        ) : (
          <SSOForm
            form={form}
            onFinish={onFinish}
            onValuesChange={onValuesChange}
            onCancel={onCancel}
            onTest={onTestStart}
            disabled={getIsDisabled()}
            isEditMode={isEditMode}
            isTestDisabled={isTestDisabled}
            setIsEditMode={setIsEditMode}
            setIsHistoryEnabled={setIsHistoryEnabled}
            initialValues={initialValues}
          />
        )}
      </div>

      {/* Email modal */}
      <Modal
        data-testid={`saml-modal`}
        data-cy={`saml-modal`}
        title={'Test SSO Settings'}
        open={isModalVisible}
        onOk={onModalOk}
        okButtonProps={{ htmlType: 'submit', disabled: isLoadingSsoTest }}
        okText={'Run Test'}
        onCancel={onModalCancel}
        cancelButtonProps={{ disabled: isLoadingSsoTest }}
        // closable={false}
        // maskClosable={false}
      >
        <Form form={formTest} onFinish={onTestFinish}>
          <Form.Item
            name='email'
            rules={[
              { required: true, message: 'You need to write something!' },
              {
                validator: emailValidator
              }
            ]}
          >
            <Input
              placeholder='Enter a valid email address'
              data-testid='saml-email-input'
              data-cy='saml-email-input'
              ref={ssoTestEmailInput}
              disabled={isLoadingSsoTest}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default SSO
