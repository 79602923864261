import MsUrl from '../../../types/MsUrl'

const Staging: MsUrl = {
  idms: 'https://idms.staging.gappifyinc.com',
  txn_manager: 'https://tm.staging.gappifyinc.com',
  review_center: 'https://rc.staging.gappifyinc.com/',
  qje_manager: 'https://qje.staging.gappifyinc.com',
  taskmanager: 'https://tsm.staging.gappifyinc.com',
  history_logs: 'https://history.staging.gappifyinc.com',
  analytics: 'https://advanced-analytics.staging.gappifyinc.com',
  token_duration: 1800,
  token_exp_allowance: 1,
  schedule_manager: 'https://ts-v2.staging.gappifyinc.com',
  schedule_manager_env: 'staging',
  search: 'https://search.staging.gappifyinc.com',
  period_manager: 'https://period-manager.staging.gappifyinc.com',
  settings: 'https://settings.staging.gappifyinc.com',
  gappify_api: 'https://internal-api.staging.gappifyinc.com'
} as const

export default Staging
